import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '@components/Input';
import { validateInput } from '@helpers/validateInput';
import Button from '@components/Common/Button';
import { media } from '@styles/theme';
import { Link as CustomLink } from "@components/CustomLink";

const Form = styled.form``;

const FormFooter = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 20px;

    @media ${media.sm} {
      button {
        min-width: unset;
      }
    }
`;

const InputWrapper = styled.div`
  margin-top: 20px;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  height: 0;
  position: relative;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-bottom: 1rem;
  border-radius: .25rem;

  &.with-error {
      margin-top: 30px;
      height: 60px;
      padding: .75rem 1.25rem;
      border: 1px solid transparent;
  }
`;

const initialState = {
  username: {
    value: "",
    error: "",
    hasError: false,
    isValid: false,
    touched: false,
  },
  password: {
    value: "",
    error: "",
    hasError: false,
    isValid: false,
    touched: false,
  }
};

const ManualForm:React.FC<{ forgotPassWordCallback: () => void }> = () => {
  const [state, setState] = useState(initialState);
  const [hostName, setHostName] = useState("");
  const [message, setMessage] = React.useState(null);
  const [errMessage, setErrMessage] = React.useState("");

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      setHostName(`${window.location.protocol}//${window.location.hostname}${window.location.port !== "" ? `:${window.location.port}`:""}/hr-counterpart`);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      setMessage(params.get("message"));
    }
  });

  React.useEffect(() => {
    if (message !== null) { 
      setErrMessage(message);
      setTimeout(() => setErrMessage(""), 5000);
    };
  }, [message]);

  const handleInput = (e: any) => {
    const {name, value} = e.target;
    const { hasError, error, isValid } = validateInput(name, value);

    setState((s) => ({
      ...s,
      [name]: { value, hasError, error, isValid }
    }));
  };

  const handleSubmit = (e: any) => {
    let errorInInput = false;

    Object.entries(state).forEach(([key, val]) => {
      if (!val) return
      const { hasError, error } = validateInput(key, val.value);

      if (hasError) {
        errorInInput = true;
      }
      
      if (state[key].value === "") {
        setState((s) => ({
          ...s,
          [key]: { value: val.value, hasError, error, touched: true }
        }));
      }
      
    })

    if (errorInInput) {
      e.preventDefault();
    }
  }

  return (
    <>
      <ErrorContainer className={`${errMessage.length > 0 ? "with-error":""}`}>{errMessage}</ErrorContainer>
      <Form action={process.env.GATSBY_HR_SIGN_IN_URL} method="POST" onSubmit={handleSubmit}>
        <Input
          placeholder="Policyholder Email"
          hasError={state.username.hasError}
          name="username"
          validText=""
          errorText={state.username.error}
          inputValue={state.username.value}
          handleInput={handleInput}
          className="two-cols"
          touched={state.username.touched}
          noValidation
        />
        <InputWrapper>
          <Input
            hasError={state.password.hasError}
            placeholder='Password'
            name="password"
            errorText={state.password.error}
            inputValue={state.password.value}
            handleInput={handleInput}
            validText=""
            className="two-cols"
            touched={state.password.touched}
            type="password"
            noValidation
          />
        </InputWrapper>
        <input type="hidden" name="logoutUrl" value={hostName} />
        <FormFooter>
          <CustomLink style={{ paddingRight: 10 }} type="darkGrey" link={{ url: "https://counterpart.enquiron.com/App/Account/ForgotPassword", link_type: "Web" }} target="_blank">Forgot your password?</CustomLink>
          <Button type="submit">
            Sign in
          </Button>
        </FormFooter>
      </Form>
    </>
  )
};

export default ManualForm;
