import React from "react";
import styled from "styled-components";

import Layout from 'components/Layout';
import Seo from 'components/Seo';

import { Wrapper, Container, H1, H2CSS, P1, P1CSS, P2, H1CSS } from '@components/styled';
import { media } from '@styles/theme';
import { Link as CustomLink } from '@components/CustomLink';
import SignInForm from '@components/HrCounterpart/SignInForm';

import BackgroundLogin from "@assets/HRCounterpartBackground.png"
import Logo from '@assets/hr-logo.svg';


const StyledWrapper = styled(Wrapper)`
  height: 100vh;
  padding-top: ${({ theme }) => theme.sizing.xxl.pageMargin};
  padding-bottom: ${({ theme }) => theme.sizing.xxl.pageMargin} !important;

  @media ${media.lg} {
    padding-top: ${({ theme }) => theme.sizing.lg.pageMargin};
    padding-bottom: ${({ theme }) => theme.sizing.lg.pageMargin} !important;
  }

  @media ${media.sm} {
    padding: 0 !important;
  }

  ${Container} {
    height: 100%;

    @media ${media.sm} {
      padding: 0;
    }
  }
`

const StyledContainer = styled(Container)`
  max-width: unset;
  padding: 0 ${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.lg} {
    padding: 0 ${({theme}) => theme.sizing.lg.pageMargin};
  }
`;

const FormWrapper = styled.div``

const Content = styled.div`
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

  @media ${media.lg} {
    grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  @media ${media.sm} {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 100px;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  @media ${media.sm} {
    min-height: 340px;
  }

  img {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
  }
`

const LeftColumn = styled.div``

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RightColumnContent = styled.div`
  margin: auto;
  max-width: 550px;
  width: 100%;

  ${P1} {
    a {
      ${P1CSS};
    }
  }

  @media ${media.lg} {
    max-width: 450px;
  }

  @media (max-width: 1200px) { 
    max-width: unset;
  }

  @media ${media.sm} {
    padding: 20px;
    padding-top: 50px;
    margin-top: 0;
    padding-bottom: 150px;
  }

  @media ${media.s} {
    width: 100%;
    padding: 0 ${({theme}) => theme.sizing.s.pageMargin};
    padding-top: 50px;
    padding-bottom: 150px;
  }

  ${H1} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 400;
    text-align: left;

    @media ${media.s} {
      ${H2CSS};
    }
  }

  ${P1} {
    margin: 20px 0 40px 0;
    text-align: left;

    @media ${media.s} {
      margin: 7px 0 20px 0;
    }
  }
`

const BottomDetails = styled.div`
  ${P2} {
    margin: 10px 0 0 0;
  }
`

const Heading = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 2;
  height: 42px;
  width: 294px;

  @media ${media.sm} {
    left: 20px;
    top: 20px;
    width: 200px;
    height: 26px;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
  }

  .login_logo div:first-child {

    @media ${media.sm} {
      height: 25px;
    }
  }
`

const ImageText = styled.div`
  z-index: 2;
  padding: 0 40px 40px 40px;

  @media ${media.sm }{
    padding: 0 20px 20px 20px;
  }

  span {
    ${H1CSS};
    color: ${({theme}) => theme.colors.light};
  }
`;

const HRCounterpartLogin = () => {
  return (
    <Layout hideFooter hideHeader location={"hr-counterpart/"}>
       <Seo 
        title="HR Counterpart - Management Liability Insurance for the 21st Century Workplace"
        description="Counterpart is the first management liability insurtech that fosters deeper partnerships between brokers and insureds"
        keywords="yourcounterpart, counterpart, insurance, liability" />
      <StyledWrapper>
        <StyledContainer>
          <Content>
            <Heading>
              <CustomLink link={"/"} type="noWrapper">
                <img src={Logo} alt="login logo" />
              </CustomLink>
            </Heading>
            <LeftColumn>
              <ImageWrapper>
                <img src={BackgroundLogin} alt="People speaking" />
                <ImageText>
                  <span>
                    Get answers to your most pressing employment challenges
                  </span>
                </ImageText>
              </ImageWrapper>
            </LeftColumn>
            <RightColumn>
              <RightColumnContent>
                <H1>Welcome policyholder!</H1>
                <P1>If you’re a broker, please login <CustomLink type="darkGrey" link={{ url: "https://app.yourcounterpart.com/login", link_type: "Web" }}>here</CustomLink></P1>
                <FormWrapper>
                  <SignInForm />
                  <BottomDetails>
                  </BottomDetails>
                </FormWrapper>
              </RightColumnContent>
            </RightColumn>
          </Content>
        </StyledContainer>
      </StyledWrapper>
    </Layout>
  )
}

export default React.memo(HRCounterpartLogin);
